<script lang="ts">
  import type { FeaturedData } from "./load";
  import { type Options, Splide, SplideSlide } from "@splidejs/svelte-splide";
  import "@splidejs/svelte-splide/css";
  import resolve from "@jridgewell/resolve-uri";

  export let featureds: FeaturedData[];

  const options: Options = {
    type: "loop",
    focus: "center",
    width: "64rem",
    gap: "1rem",
    autoWidth: true,
    autoplay: true,
    pagination: false,
  };
</script>

<Splide class="mx-auto" {options}>
  {#each featureds as { template, design, kitId, params }}
    <SplideSlide class="m-auto">
      <div class="flex-center">
        <a
          class="group p-1 bg-red-6 space-y-1"
          href={`/editor/colors/${template.slug}/${design.slug}#${params}`}
        >
          <div class="overflow-hidden bg-white">
            <img
              class="-my-2 transition-opacity group-hover:opacity-50"
              loading="lazy"
              decoding="async"
              src={resolve(
                `/_image/thumbnail/composite/${kitId}?${params}`,
                import.meta.env.PUBLIC_IMAGE_URL,
              )}
              alt={`${design.name} - ${template.name}`}
              width={320}
              height={180}
            />
          </div>
          <div
            class="p-2 text-center group-hover:(bg-primary text-white) transition-colors"
            aria-hidden="true"
          >
            <p class="font-bold text-xl">{design.name}</p>
            <p>{template.name}</p>
          </div>
        </a>
      </div>
    </SplideSlide>
  {/each}
</Splide>
